import "./App.css";

function App() {
  return (
    <div className="App">
      <div id="main-container">
        <br />
        <hr />
        Step 1: Download or select the transcription model. You will only need
        to do this once.
        <br />
        <br />
        Step 2: Once the download is done, click the "Start" button and start
        speaking
        <br />
        <br />
        <div id="model-whisper">
          Download/Select model:
          <button
            id="fetch-whisper-small-en-q5_1"
            onClick={() => window.loadWhisper("small-en-q5_1")}
          >
            English Model
          </button>
          <button
            id="fetch-whisper-small-q5_1"
            onClick={() => window.loadWhisper("small-q5_1")}
          >
            Multilingual Model
          </button>
          <br />
          <span id="model-whisper-status"></span>
          <br />
          <span id="fetch-whisper-progress"></span>
          <br />
          {/* Upload audio file&nbsp;
          <input
            type="file"
            id="whisper-file"
            name="file"
            onChange={(event) => window.loadFile(event, "whisper.bin")}
          /> */}
        </div>
        <div id="input" style={{ visibility: "hidden" }}>
          <button
            id="start"
            onClick={() => {
              window.onStart();
            }}
          >
            Start
          </button>
          &nbsp;
          <button id="stop" onClick={() => window.onStop()}>
            Stop
          </button>
          <br />
          <button id="clear" onClick={() => window.clearCache()}>
            Clear Cache
          </button>
        </div>
        <br />
        <div id="state">
          Status:{" "}
          <b>
            <span id="state-status">not started</span>
          </b>
          <pre id="state-transcribed">
            [The transcribed text will be displayed here]
          </pre>
        </div>
        <hr />
        <div id="output">{/* Debug output: <br /> */}</div>
        <br />
      </div>
    </div>
  );
}

export default App;
